.gifPlayer {
  position: relative;
  width: 100%;
  height: auto;
  .thumbnail {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    svg, .IconButton {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px; /* Adjust as needed */
      height: 50px; /* Adjust as needed */
    }
    img{width: 100%; height: auto; opacity: 0.8;}
  }
}
