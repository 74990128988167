.mediaPlayer {
    video {
        max-height: 70vh;
        border-radius: 20px;
    }
}

.preloadImage {
    width: 100%;
}

.postImgContainer{
    max-width: 640px;
    max-height: 510px;
    border-radius:20px;
    overflow: hidden;
    margin: 20px 0;
    margin-left: 10px;
    .postImage{
        max-width: 100%;
        min-height: 350px;
        max-height: 510px;
        height: 100%;
        border-radius:20px;
        object-fit: cover;
    }
}