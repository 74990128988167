.reelBtn {
  background: rgba(255, 255, 255, 0.267);
  width: 0% !important;
  border-radius: 20px !important;
  -webkit-padding-start: 0px !important;
  padding-inline-start: 0px !important;
  -webkit-padding-end: 0px !important;
  padding-inline-end: 0px !important;
}
.reelBtn:hover {
  background: rgba(255, 255, 255, 0.361) !important;
}
