.videoPlayer {
  position: relative;

  width: 516px;
  height: 516px;
  border-radius: 20px;
  overflow: hidden;
  margin: 20px 0;
  background-color: black;
  // width: 100%;
  // height: auto;
  // .thumbnailCont {
  //   cursor: pointer;
  //   position: relative;
  //   width: 100%;
  //   height: 100%;
  //   svg {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //     width: 50px; /* Adjust as needed */
  //     height: 50px; /* Adjust as needed */
  //   }
  //   img{width: 100%; height: auto; opacity: 0.8;}
  // }
  .thumbnailCont {
    width: 100%;
    height: 100%;
    background-color: black;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.controlCont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  position: absolute;
  bottom: 10px;
  z-index: 1;
  // background-color: #1c1c1c;

  .barCont {
    width: 100%;
  }

  .btnsCont {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    .playBtns {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .rightSideCont {
      display: flex;
      align-items: center;
      div {
        margin: 0 10px;
      }
      .timeCont {
        color: white;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.outerTime {
  background-color: #1c1c1c;
  border-radius: 5px;
  color: white;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 5px 10px;
}

.rangeBar {
  accent-color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.33);
  width: 100%;
  height: 4px;
  cursor: pointer;
  &:hover {
  height: 5px;
  }
}

