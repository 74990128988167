.mediaPlayer {
  video {
    // max-height: 70vh;
  }
}

.preloadImage {
  // max-width: 100%;
  border-radius: 15px 15px 0px 0px;
  // max-height: 510px;
  object-fit: cover;
  object-position: left;
  // background-color: red;
}

.postImgContainer {
  max-width: 640px;
  max-height: 510px;
  border-radius: 20px;
  overflow: hidden;
  margin: 20px 0;
  .postImage {
    max-width: 100%;
    min-height: 350px;
    max-height: 510px;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
  .postbannerImage {
    max-width: 100%;
    max-height: 510px;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
}

.articalImgContainer {
  max-width: 640px;
  max-height: 510px;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  margin-top: 20px;
  .articalImage {
    max-width: 100%;
    min-height: 350px;
    max-height: 510px;
    height: 100%;
    border-radius: 20px 20px 0 0;
    object-fit: cover;
  }
}
